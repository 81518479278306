<template>
  <b-tooltip :target="target" placement="bottom">
    <div class="tooltip-product">
      <div class="batch">{{ transaction.batch }}</div>
      <div>Data de cadastro do lote: <span class="date">{{ moment(transaction.created_at).format('DD/MM/YYYY') }}</span></div>
      <div>Data de fabricação: <span class="date">{{ transaction.manufacturing_date ? moment(transaction.manufacturing_date).format('DD/MM/YYYY') : 'Data não informada' }}</span></div>
      <div>
        Data de validade:
        <span v-bind:class="{ date: true, danger: isExpired(transaction.expiration_date), warning: isToExpire(transaction.expiration_date, transaction.product.expiration_notice) }">
          {{ moment(transaction.expiration_date).format('DD/MM/YYYY') }}
        </span>
      </div>
    </div>
  </b-tooltip>
</template>
<script>
export default {
  props: {
    target: { type: String, required: true },
    transaction: { type: Object, required: true },
  },
  methods: {
    isExpired(date) {
      const now = new Date();
      const expirationDate = new Date(date);
      return now.getTime() > expirationDate.getTime();
    },
    isToExpire(date, expirationNotice) {
      if (this.isExpired(date)) return false;
      const expirationNoticeDate = new Date();
      expirationNoticeDate.setDate(expirationNoticeDate.getDate() + expirationNotice);
      const expirationDate = new Date(date);
      return expirationNoticeDate.getTime() > expirationDate.getTime();
    },
  }
}
</script>
<style lang="scss" scoped>
  .tooltip-product {
    text-align: left;
    font-size: 12px;

    .batch {
      font-size: 16px;
      font-weight: 600;
      color: var(--blue-500);
    }
    .date {
      font-size: 14px;
      font-weight: 600;
      color: var(--type-active);
    }
    .danger {
      color: var(--states-error);
    }
    .warning {
      color: var(--orange);
    }
  }
</style>
