<template>
  <div class="table-container">
    <b-table-simple class="p-table" striped>
      <thead>
        <tr>
          <td class="td-header">Produto</td>
          <td class="td-header">Lote</td>
          <td class="td-header">Qtde. Lote</td>
          <td class="td-header">Qtde. Estoque</td>
          <td class="td-header">Fabricação</td>
          <td class="td-header">Validade</td>
          <td class="td-header">Valor de compra</td>
          <td class="td-header">Valor de venda</td>
          <td class="td-header"></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="transaction in transactions" :key="transaction.id">
           <td :class="{'td-body': true, trashed: transaction.product.deleted_at }">
            <span :id="`tooltip_${transaction.warehouse_entry.id}`">{{ transaction.product.name }}</span>
             <v-batch-detail
              :target="`tooltip_${transaction.warehouse_entry.id}`"
              :transaction="transaction"
            />
          </td>
          <td class="td-body td-simple">{{ transaction.batch || '-' }}</td>
          <td class="td-body td-simple">{{ normalizeProductNumber(transaction.quantity ? transaction.quantity : 0, 2) }}</td>
          <td class="td-body td-simple">{{ normalizeProductNumber(transaction.batch_balance ? transaction.batch_balance?.balance : 0, 2) }}</td>
          <td class="td-body td-simple">{{ transaction.manufacturing_date ? new Date(transaction.manufacturing_date).toLocaleDateString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }) : '-' }}</td>
          <td class="td-body td-simple">
            <div class="quantity">
              <div v-bind:class="{ danger: isExpired(transaction.expiration_date), warning: isToExpire(transaction.expiration_date, transaction.product.expiration_notice) }">
                {{ new Date(transaction.expiration_date).toLocaleDateString('pt-BR', { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }) }}
              </div>
              <span :id="`tooltip_${transaction.id}`" v-if="isExpired(transaction.expiration_date)" class="icon-box"><v-info-danger class="icon stroke"  /></span>
              <span :id="`tooltip_${transaction.id}`" v-if="isToExpire(transaction.expiration_date, transaction.product.expiration_notice)" class="icon-box"><v-info-warning class="icon stroke"  /></span>
              <b-tooltip :target="`tooltip_${transaction.id}`" placement="bottom">
                {{ isExpired(transaction.expiration_date) ? 'Lote vencido' : null }}
                {{ isToExpire(transaction.expiration_date, transaction.product.expiration_notice) ? `Faltam ${daysToExpire(transaction.expiration_date)} dias para o vencimento deste lote` : '' }}
              </b-tooltip>
            </div>
          </td>
          <td class="td-body td-simple">{{ parseNumberToMoney(transaction.quantity * transaction.purchase_price) }}</td>
          <td class="td-body td-simple">{{ parseNumberToMoney(transaction.quantity * transaction.sale_price) }}</td>
          <td class="td-body">
            <div class="more-with-menu">
              <v-more-vertical class="more-icon" />
              <div class="menu">
                <b-button 
                  :disabled="transaction.product.deleted_at !== null || 
                  transaction.product.product_balance?.balance === 0 || 
                  transaction.quantity === 0 || 
                  transaction.batch_balance?.balance <= 0 || 
                  transaction.batch_balance?.balance < transaction.quantity " 
                  variant="link" @click="outputProduct(transaction)"
                  >Efetuar saída total do lote
                </b-button>
                <b-button
                  variant="link"
                  @click="showDetails(transaction)"
                  >Ver detalhes</b-button
                >
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <v-empty-table-results
      emptyMessage="Cadastre uma entrada para visualizar os lotes aqui."
      :loading="loading"
      :length="transactions ? transactions.length : 0"
      :hasFilter="hasFilter"
    />
  </div>
</template>
<script>
import MoreVertical from '@/assets/icons/more-vertical.svg'
import InfoWarning from '@/assets/icons/info-circle-warning.svg'
import InfoDanger from '@/assets/icons/info-circle-danger.svg'
import { parseNumberToMoney } from '@/utils/moneyHelper';
import { normalizeProductNumber } from '@/utils/product-helper'
import EmptyTableResults from '@/components/General/EmptyTableResults'
import BatchDetail from '@/components/Warehouse/BatchDetail'
import moment from 'moment';

export default {
  props: {
    transactions: { type: Array, required: true },
    outputProduct: Function,
    showDetails: Function,
    loading: Boolean,
    hasFilter:  { validate: v => true },
  },
  components: {
   'v-more-vertical': MoreVertical,
   'v-info-warning': InfoWarning,
   'v-info-danger': InfoDanger,
   'v-empty-table-results': EmptyTableResults,
   'v-batch-detail': BatchDetail
  },
  methods: {
    parseNumberToMoney,
    normalizeProductNumber,
    isExpired(date) {
      const now = new Date();
      const expirationDate = new Date(date);
      return now.getTime() > expirationDate.getTime();
    },
    isToExpire(date, expirationNotice) {
      if (this.isExpired(date)) return false;
      const expirationNoticeDate = new Date();
      expirationNoticeDate.setDate(expirationNoticeDate.getDate() + expirationNotice);
      const expirationDate = new Date(date);
      return expirationNoticeDate.getTime() > expirationDate.getTime();
    },
    daysToExpire(date) {
      const expiredDate = moment(date)
      const currentDate = moment();

      const duration = moment.duration(expiredDate.diff(currentDate));
      return parseInt(duration.asDays());
    }
  }
}
</script>
<style lang="scss" scoped>
.p-table {
  width: 100%;
  text-align: left;
  margin: 20px 0;

  .td-header {
    font-weight: 700;
    font-size: 14px;
    color: var(--type-active);
    border: none;
    padding: 20px 10px;
  }
  .td-body {
    padding: 20px 10px;
    border-top: 1px dashed var(--neutral-300);
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-blue);
  }
  .td-simple {
    font-weight: normal !important;
  }
  .number {
    font-family: 'Red Hat Display';
    font-weight: 700;
    color: var(--neutral-500);
  }
  .trashed {
    text-decoration: line-through;
  }
  .icon {
    width: 18px;
    height: 18px;
    margin: -4px 0 0 5px;
  }
  .edit-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--blue-500);
  }
  .delete-text {
    font-size: 16px;
    font-weight: 600;
    color: var(--states-error);
  }
  .quantity {
    display: inline-flex;
    align-items: center;
  }
  .danger {
    color: var(--states-error);
  }
  .warning {
    color: var(--orange);
  }
}
.more-with-menu {
  width: 24px;
  height: 28px;
  position: relative;
  display: inline-block;

  .more-icon {
    width: 24px;
    height: 24px;
    stroke: var(--neutral-500);
    transition: all 0.5s;
    cursor: pointer;
  }

  .menu {
    position: absolute;
    top: 28px;
    right: 0;
    background: var(--neutral-000);
    border: 1px solid var(--neutral-200);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(12, 29, 89, 0.1), 0px 4px 10px -8px rgba(12, 29, 89, 0.2);
    border-radius: 8px;
    opacity: 0;
    transition: all 0.5s;
    flex-direction: column;
    align-items: start;
    display: none;
    width: 144px;

    .mtb-12 {
      margin: 12px 0;
    }

    .btn {
      font-family: 'Nunito Sans';
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 150%;

      &.remove {
        color: var(--states-error);
      }

      &.btn-link {
        padding: 12px 28px 12px 16px;
      }
    }
  }

  &:hover {
    .more-icon {
      transform: rotate(90deg);
    }

    .menu {
      opacity: 1;
      display: flex;
      z-index: 10;
    }
  }
}
.tooltip {
  opacity: 1;
  .tooltip-inner {
      background-color: red !important;
      color: #525C7A;
      max-width: none;
      box-shadow: 0px 16px 22px -8px #0C1D5933;
      border: 1px solid #D9DFF2;
      padding: 10px;
  }
}
</style>
