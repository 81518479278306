<template>
  <div class="pt-container">
    <div class="pt-header">
      <div class="pt-title">
        Controle de lotes
        <span id="help-icon" class="icon-box"><v-help-circle class="icon stroke" /></span>
        <b-tooltip target="help-icon" placement="bottom">
          Faça a gestão de lotes e tenha controle dos produtos como data de fabricação, data de validade, aviso de vencimento próximo e produtos vencidos.
        </b-tooltip>
      </div>

      <div>
        <b-button class="wh-button no-print" variant="outline-primary" outline @click="getXlsx">
            Download xlsx
        </b-button>
      </div>
    </div>

    <div class="row">
      <div class="col-5">
        <b-form-group class="form-group">
          <label for="search">Produto</label>
          <b-form-input
            autocomplete="off"
            class="placeholder-color"
            id="search"
            debounce="500"
            placeholder="Buscar produto"
            v-model="query"
          />
        </b-form-group>
      </div>

      <div class="col-3">
        <v-select
          label="Vencimento"
          placeholder="Selecionar"
          v-model="expirationType"
          v-bind:options="[ { label: 'Selecionar', value: null }, { label: 'Vencimento próximo',value: 'toExpire' }, { label: 'Produtos vencidos', value: 'expired' } ]"
          v-on:onChange="value => (this.expirationType = value)"
        />
      </div>

      <div class="col-2">
        <v-periods
          label="Validade"
          @select="value => handlePeriod('period', value)"
        />
      </div>

      <div class="col-2">
        <v-periods
          label="Fabricação"
          @select="value => handlePeriod('manufacturing', value)"
        />
      </div>
    </div>

    <v-batches-table
      :transactions="transactions"
      :outputProduct="onOutputTransaction"
      :showDetails="onShowDetailsClick"
      :loading="loading"
      :hasFilter="hasFilter"
    />

    <div class="pagination-position">
      <b-pagination
        size="sm"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
      />
    </div>

    <v-product-transaction-modal
      identifier="show-warehouse-product-entry"
      :transaction="transaction"
      :disabled="true"
    />

  </div>
</template>
<script>
import { saveAs } from 'file-saver';
import HelpCircle from '@/assets/icons/help-circle.svg'
import Periods from '@/components/General/Periods'
import Select from '@/components/Select'
import BatchesTable from '@/components/Warehouse/BatchesTable'
import ProductTransactionModal from '@/components/Warehouse/ProductTransactionModal'
import { EventBus } from '@/utils/eventBus';

export default {
  name: 'products-table',
  components: {
    'v-help-circle': HelpCircle,
    'v-periods': Periods,
    'v-select': Select,
    'v-batches-table': BatchesTable,
    'v-product-transaction-modal': ProductTransactionModal,
  },
  props: {
    onEditOutputClick: Function,
  },
  created () {
    if (this.$route.query.v === 'p') {
      this.expirationType = 'toExpire';
    } else if (this.$route.query.v === 'v') {
      this.expirationType = 'expired';
    }
    this.getTransactionBatches();

    EventBus.$on('reloadBatches', () => this.getTransactionBatches(true));
  },
  data() {
    const clinic = JSON.parse(localStorage.getItem('clinic'));
    return {
      clinicId: clinic.id,
      page: 1,
      limit: 0,
      count: 0,
      query: '',
      expirationType: null,
      periodStart: null,
      periodEnd: null,
      manufacturingStart: null,
      manufacturingEnd: null,
      transactions: [],
      transaction: null,
      output: null,
      loading: false,
      hasFilter: false,
    }
  },
  methods: {
    handlePeriod(key, value) {
      this[`${key}Start`] = value.start;
      this[`${key}End`] = value.end;
    },
    getTransactionBatches(hideLoading) {
      this.loading = true;
      this.hasFilter = (this.query || this.expirationType || this.periodStart || this.manufacturingStart || this.manufacturingEnd);
      let isLoading = null;
      if (hideLoading) {
        isLoading = this.$loading.show();
      }
      this.api.getTransactionBatches(this.clinicId, this.page, this.query, this.expirationType, this.periodStart, this.periodEnd, this.manufacturingStart, this.manufacturingEnd)
      .then(res => {
        this.transactions = res.data.data;
        this.count = res.data.total;
        this.limit = res.data.per_page;
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => {
        this.loading = false;
        isLoading && isLoading.hide();
      });
    },
    onShowDetailsClick(transaction) {
      this.transaction = Object.assign({}, transaction);
      this.$bvModal.show('show-warehouse-product-entry')
    },
    onOutputTransaction(transaction) {
      delete transaction.id;
      const output = {
        transactions: [transaction]
      }
      this.onEditOutputClick(output);
    },
    getXlsx() {
      const isLoading = this.$loading.show();
      const payload = {
        'query': this.query, 
        'expirationType': this.expirationType, 
        'periodStart': this.periodStart, 
        'periodEnd': this.periodEnd, 
        'manufacturingStart': this.manufacturingStart,
        'manufacturingEnd': this.manufacturingEnd
      }
      this.api.getWarehouseBatchesExport(this.clinicId, payload)
      .then(res => {
        saveAs(
          new Blob([res.data], {
            type: 'application/vnd.ms-excel',
          }),
          'lotes.xlsx'
        );
      })
      .catch(err => this.$toast.error(err.message))
      .finally(() => isLoading.hide());
    },
  },
  watch: {
    query: function() { this.getTransactionBatches(); },
    expirationType: function() { this.getTransactionBatches(); },
    periodStart: function() { this.getTransactionBatches(); },
    manufacturingStart: function() { this.getTransactionBatches(); },
    page: function() { this.getTransactionBatches(); },
  }
}
</script>
<style scoped lang="scss">
  .pt-container {
    padding: 30px 10px;
    .pt-header {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 20px 0 30px 0;
    }

    .placeholder-color::placeholder {
      color: var(--type-placeholder);
    }

    .pt-title {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }
    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
    .pagination-position {
      width: 100%;
      display: inline-flex;
      justify-content: flex-end;
    }
    label {
      width: 100%;
      font-family: 'Nunito Sans';
      font-weight: 700;
      font-size: 16px;
      color: #525C7A;
      text-align: left !important;
      color: var(--dark-blue);
      margin-bottom: 4px;
    }
    .wh-button {
      margin: 0 0 0 10px;
      border-color: var(--blue-500);
      color: var(--blue-500);
    }
  }
</style>

